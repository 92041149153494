import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
// import * as actions from "../actions/authActions";
import * as actions from "../actions"
import history from "../history";
import PrivateRoute from "./utils/PrivateRoute";
import AdminRoute from "./utils/AdminRoute";
import "../styles/App.css";
import ReactGA from "react-ga";
import { loadReCaptcha } from "react-recaptcha-google";

//Components
import Landing from "../views/public/Landing/Landing";
import Login from "../views/public/Login/Login_V2";//Done

import Register from "../views/public/Register/Register";//Done
import Courses from "../views/private/Courses/Courses"; //Done
import Projects from "../views/private/Projects/Projects"; //Done
import StructureWorks from "../views/private/StructureWorks/StructureWorks"; //Done
import AccessManagement from "../views/private/AccessManagement/AccessManagement"; //Done
import LectureList from "../views/private/Lectures/LecturesList"; //Done
import RegisterThanks from "../views/public/RegisterThanks/RegisterThanks"; //Done
import ResetPassword from "../views/public/ResetPassword/ResetPassword";//Done
import CreatePassword from "../views/public/CreatePassword/CreatePassword";//Done
import CertificateRequest from "../views/public/CertificateRequest/CertificateRequest"
import CertificateThanks from "../views/public/CertificateRequestSubmitted/CertificateRequestSubmitted"

import AnalysisTruss2D from "../views/private/StructureWorks/SolverPages/AnalysisTruss2D"
import AnalysisTruss3D from "../views/private/StructureWorks/SolverPages/AnalysisTruss3D"

// import HelloCube from "../components/3D/ThreeJS/HelloCube";
// import HelloCube_DREI from "../components/3D/ThreeJS/HelloCube_DREI";
// import HelloParticles from "../components/3D/ThreeJS/HelloParticles";
import LearningPathways from "../components/3D/LearningPathways";

// import courseRoutes_20 from '../views/private/Course20/routes'
import courseRoutes_19 from '../views/private/Course19/routes'
// import courseRoutes_18 from '../views/private/Course18/routes'
import courseRoutes_17 from '../views/private/Course17/routes'
// import courseRoutes_16 from '../views/private/Course16/routes'
// import courseRoutes_15 from '../views/private/Course15/routes'
// import courseRoutes_14 from '../views/private/Course14/routes'
// import courseRoutes_13 from '../views/private/Course13/routes'
// import courseRoutes_12 from '../views/private/Course12/routes'
// import courseRoutes_11 from '../views/private/Course11/routes'
// import courseRoutes_10 from '../views/private/Course10/routes'
// import courseRoutes_9 from '../views/private/Course9/routes'
// import courseRoutes_8 from '../views/private/Course8/routes'
// import courseRoutes_7 from '../views/private/Course7/routes'
// import courseRoutes_6 from '../views/private/Course6/routes'
// import courseRoutes_5 from '../views/private/Course5/routes'
// import courseRoutes_4 from '../views/private/Course4/routes'
// import courseRoutes_3 from '../views/private/Course3/routes'
// import courseRoutes_1 from '../views/private/Course1/routes'
// import projectRoutes_1 from '../views/private/Project1/routes'
// import projectRoutes_2 from '../views/private/Project2/routes'
// import projectRoutes_3 from '../views/private/Project3/routes'
// import projectRoutes_4 from '../views/private/Project4/routes'


history.listen(location => {
	ReactGA.set({ page: location.pathname });
	ReactGA.pageview(location.pathname);
});

class App extends React.Component {
	constructor(props) {
		super(props);
		//Check if user is logged in and update store
		this.props.fetchUser();   
    this.props.fetchCompletions() //Record of lectures completed     
	}

	componentDidMount() {
		//Initialise Google ReCaptcha
		loadReCaptcha()
		ReactGA.pageview(window.location.pathname); //Log a page view for the initial visit (history tracks future url changes)
    
	}

	render() {    
		return (
			<div>
				<Router history={history}>
					<div>
            {/* Auth Routes */}
						<Route exact path="/" component={StructureWorks} />  
            {/* <Route exact path="/dashboard" component={Courses} />           
						<Route exact path="/register" component={Register} /> */}
						<Route exact path="/login" component={Login} />
						{/* <Route exact path="/registerrequestthankyou" component={RegisterThanks} /> */}
						<Route exact path="/resetpassword/:token" component={CreatePassword} />
						<Route exact path="/resetpassword" component={ResetPassword} /> 
            {/* <Route exact path="/review-certificate-request" component={CertificateRequest} /> */}
            {/* <Route exact path="/revcertrequestsubmitted" component={CertificateThanks} />             */}

            {/* <Route exact path="/three" component={LearningPathways} />  */}
            
            {/* External Routes */}
            {/* <Route path='/degreetutors-home' component={() => { 
                window.location.href = 'https://www.degreetutors.com/'; 
                return null;
            }}/> */}

            <Route path='/home' component={() => { 
                window.location.href = 'https://www.engineeringskills.com/'; 
                return null;
            }}/>

            {/* External Routes */}
            {/* <Route path='/community' component={() => { 
                window.location.href = 'https://community.degreetutors.com/'; 
                return null;
            }}/> */}

            {/* External Routes */}
            {/* <Route path='/stripebilling' component={() => {                 
                window.location.href = process.env.REACT_APP_STRIPE_PORTAL_URL; 
                return null;
            }}/> */}

            {/* External Routes */}
            <Route path='/membership' component={() => { 
                window.location.href = 'https://www.degreetutors.com/membership/'; 
                return null;
            }}/>

            {/* External Routes */}
            {/* <Route path='/roadmap' component={() => { 
                window.location.href = 'https://spcarroll.notion.site/Roadmap-653eecfbfcdc4d92951c643bcc8efb02'; 
                return null;
            }}/> */}

            <Route path='/terms' component={() => { 
                window.location.href = 'https://www.degreetutors.com/terms-of-use/'; 
                return null;
            }}/>
           
            {/* Private Routes */}
						<Switch>
              <>
                {/* <Route exact path="/courses" component={Courses} /> */}
                {/* <Route exact path="/projects" component={Projects} />                 */}
                <Route exact path="/structureworks" component={StructureWorks} />
                {/* <Route exact path="/lectures" component={LectureList} /> */}

                <Route exact path="/structureworks/truss2d" component={AnalysisTruss2D} />
                <Route exact path="/structureworks/truss3d" component={AnalysisTruss3D} />


                <PrivateRoute exact path="/accessmanagement" component={AccessManagement} />

                {/* Course 20 - Analytical Modelling of Plate and Shell Structures */}
                {/* {courseRoutes_20} */}

                {/* Course 19 - Fundamentals of Reinforced Concrete Design to Eurocode 2 */}
                {courseRoutes_19}

                {/* Course 18 - Modelling and Analysis of Non-linear Cablenet Structures using Python and Blender */}
                {/* {courseRoutes_18} */}

                {/* Course 17 - Non-linear finite element analysis of 2D catenary & cable structures using Python */}
                {courseRoutes_17}

                {/* Course 16 - Multi-Degree of Freedom Dynamics, Modal Analysis and Seismic Response Simulation in Python */}
                {/* {courseRoutes_16} */}
                
                {/* Course 15 - Finite Element Analysis of Continuum Structures in Python */}
                {/* {courseRoutes_15} */}

                {/* Course 14 - Finite Element Analysis of 3D Structures using Python */}
                {/* {courseRoutes_14} */}

                {/* Course 13 - 3D Space Frame Analysis using Python and Blender */}
                {/* {courseRoutes_13} */}

                {/* Course 12 - Beam & Frame Analysis using the Direct Stiffness Method in Python */}
                {/* {courseRoutes_12} */}

                {/* Course 11 - The Direct Stiffness Method for Truss Analysis with Python */}
                {/* {courseRoutes_11} */}

                {/* Course 10 - Fundamentals of Engineering Structural Dynamics with Python */}
                {/* {courseRoutes_10} */}

                {/* Course 9 - Analysing Hybrid Indeterminate Civil Engineering Structures */}
                {/* {courseRoutes_9} */}

                {/* Course 8 - Mastering Virtual Work for Civil Engineering */}
                {/* {courseRoutes_8} */}

                {/* Course 7 - Fundamentals of Virtual Work for Civil Engineers */}
                {/* {courseRoutes_7} */}

                {/* Course 6 - Indeterminate Structures & The Moment Distribution Method */}
                {/* {courseRoutes_6} */}

                {/* Course 5 - Moment Distribution Method: Analysis Bootcamp */}
                {/* {courseRoutes_5} */}
                
                {/* Course 4 - Shear Forces & Bending Moments: Analysis Bootcamp */}
                {/* {courseRoutes_4} */}

                {/* Course 3 - Mastering Shear Force and Bending Moment Diagrams */}
                {/* {courseRoutes_3} */}

                {/* Course 1 - Fundamentals of Structural Analysis */}
                {/* {courseRoutes_1} */}

                {/* Project 4 - Simulating Crowd-induced Vibrations using the Duhamel Integral */}
                {/* {projectRoutes_4} */}

                {/* Project 3 - Building a Mohr’s Circle Calculator for Stress Analysis in Python */}
                {/* {projectRoutes_3} */}

                {/* Project 2 - Building a Beam Deflection Calculator */}
                {/* {projectRoutes_2} */}

                {/* Project 1 - Building a Shear Force and Bending Moment Diagram Calculator in Python */}
                {/* {projectRoutes_1} */}

                
              
              </>
						</Switch>
					</div>
				</Router>
			</div>
		);
	}
}

export default connect(null, actions)(App);
