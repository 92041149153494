import axios from "axios";
import history from "../history";
import { reset } from "redux-form";
import { FETCH_USER, FETCH_ERRORS, FETCH_FLASH } from "./types";

// ========================================================
export const fetchUser = () => async dispatch => {
	const res = await axios.get("/api/current_user");

	//Destructure returned data
	let { user, errors } = res.data;

	// Dispatch User data (user or false)
	dispatch({ type: FETCH_USER, payload: user });

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const editUser = values => async dispatch => {
	const res = await axios.patch("/api/edituser", values);

	//Destructure returned data
	const { user, errors, flash } = res.data;

	if (user) {
		//Dispatch data action to reducer
		dispatch({ type: FETCH_USER, payload: user });
	}

	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		// No flash returned from API, set to empty strings and dispatch
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}
};

// ========================================================
export const loginRequest = values => async dispatch => {
	const res = await axios.post("/api/login", values);

	//Destructure returned data
	const { user, flash, errors } = res.data;

	// Dispatch User data (user or false)
	dispatch({ type: FETCH_USER, payload: user });

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}

	//Programmatic navigation here
	if (user) {
		history.push("/courses");
	} else {
		history.push("/login");
	}
};

// ========================================================
export const logoutRequest = () => async dispatch => {
	const res = await axios.get("/api/logout");

	//Destructure returned data
	const { user, flash } = res.data;

	// Dispatch User data (user or false)
	dispatch({ type: FETCH_USER, payload: user });

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	//Programmatic navigation here
	history.push("/login");
};

// ========================================================
export const registerRequest = values => async dispatch => {  
	const res = await axios.post("/api/register", values);

	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	dispatch({ type: FETCH_ERRORS, payload: errors });

	//Programmatic navigation here
	if (errors.length < 1) {
		history.push("/registerrequestthankyou");
	}
};

// ========================================================
export const resetPasswordRequest = values => async dispatch => {
	const res = await axios.post("/api/newpassword", values);

	//Destructure returned data
	const { flash, errors } = res.data;

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}

	dispatch(reset("resetForm"));
};

// ========================================================
export const createPasswordRequest = (values, token) => async dispatch => {
	const res = await axios.post("/api/reset/" + token, values);

	//Destructure returned data
	const { user, flash, errors } = res.data;

	// Dispatch User data (user or false)
	dispatch({ type: FETCH_USER, payload: user });

	// Dispatch flash messaging
	if (flash) {
		dispatch({ type: FETCH_FLASH, payload: flash });
	} else {
		//No flash returned from API, set to empty strings
		let flash = {
			type: "",
			message: ""
		};
		dispatch({ type: FETCH_FLASH, payload: flash });
	}

	// Dispatch error messages messaging
	if (errors) {
		dispatch({ type: FETCH_ERRORS, payload: errors });
	} else {
		let errors = [];
		dispatch({ type: FETCH_ERRORS, payload: errors });
	}

	//Programmatic navigation here
	if (errors.length < 1) {
		history.push("/dashboard");
	}
};
